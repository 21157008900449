.shopkeeperreportdetails{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 20px;
}


.shopkeeperreportdetails button:nth-child(1){
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.73px;
    color: #000000;
    margin: 0;
    cursor: pointer;
}


.shopkeeperreportdetails button:nth-child(2){
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 10px;
    background-color: #00F076;
    border: none;
    outline: none;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.73px;
    color: #2E4658;
    margin: 0;
    cursor: pointer;
}

.shopkeeperreportdetails button:nth-child(3){
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 10px;
    background-color: #F44336;
    border: none;
    outline: none;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.73px;
    color: #FFFFFF;
    margin: 0;
    cursor: pointer;
}