.kyc {
  display: flex;
  align-items: center;
}

.kyc1 {
  width: 88px;
  height: auto;
  /* padding: 8px 16px 8px 16px; */
  padding: 8px;
  border-radius: 9px;
  font-family: "Lexend", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.73px;
  color: #ffffff;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kyc2 {
  margin-top: 15px;
  width: 30%;
}

.kyc3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.kyc3 label {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  color: #000000;
  letter-spacing: 0.2px;
  margin: 0;
}

.kyc4 {
  width: auto;
  height: 106px;
  padding: 10px;
  border: 2px dashed #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.kyc4 p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  color: #ffa500;
  letter-spacing: 0.2px;
  margin: 0;
}

.kyc5 {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 50px;
  gap: 10px;
}

.kyc5 button:nth-child(1) {
  width: auto;
  height: auto;
  padding: 9.88px 10.53px 9.88px 10.53px;
  border-radius: 6.58px;
  border: 1px solid #2e4658;
  outline: none;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.73px;
  color: #2e4658;
  letter-spacing: 0.2px;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

.kyc5 button:nth-child(2) {
  width: auto;
  height: auto;
  padding: 9.88px 10.53px 9.88px 10.53px;
  border-radius: 6.58px;
  border: 1px solid #ffa500;
  outline: none;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.73px;
  color: #ffffff;
  letter-spacing: 0.2px;
  margin: 0;
  background-color: #ffa500;
  cursor: pointer;
}
