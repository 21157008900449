.setting1{
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}



.setting2{
    display: flex;
    gap: 20px;
}




.setting3{
    width: 35%;
    /* width: 100%; */
    height: 115px;
    border-radius: 10px;
    border: 1px solid #2F5A76;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
}


.setting4{
    display: flex;
    align-items: center;
    gap: 5px;
    padding-top: 15px;
}


.setting4 h6{
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 20.68px;
    color: #2E4658;
    letter-spacing: 1%;
    margin: 0;
}


.setting3 p{
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.09px;
    color: #71717A;
    letter-spacing: 1%;
    margin: 0;
    padding-top: 15px;
}




.setting5{
    /* width: 70%; */
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 1px solid #2F5A76;
    padding: 20px;
}



.setting6{
    display: flex;
    flex-direction: column;
    gap: 20px;
}



.setting6 h6{
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 20.68px;
    color: #2E4658;
    letter-spacing: 1%;
    margin: 0;
}


.setting6 p{
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.09px;
    color: #71717A;
    letter-spacing: 1%;
    margin: 0;

}


.setting7{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
}


.setting8{
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.setting8 label{
    font-family: "Ubuntu", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.09px;
    color: #71717A;
    letter-spacing: 1%;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 5px;
}


.setting8 input{
    width: 331px;
    height: 46px;
    border-radius: 40px;
    border: 1px solid #2F5A76;
    outline: none;
    padding-left: 20px;
}


.setting8 textarea{
    width: 331px;
    height: 124px;
    border-radius: 10px;
    border: 1px solid #2F5A76;
    outline: none;
    padding-left: 20px;
    padding-top: 20px;
}

.setting9{
    margin-top: 30px;
}

.setting9 button{
    width: 100%;
    height: auto;
    padding: 5px 12px 5px 12px;
    border-radius: 50px;
    background: #2E4658;
    border: 1px solid #2E4658;
    outline: none;
    font-family: "Public Sans", sans-serif;
    font-size: 17.78px;
    font-weight: 600;
    line-height: 24.83px;
    color: #FFFFFF;
    margin: 0;
    cursor: pointer;
}



.setting10{
    margin-top: 30px;
}


.setting10 textarea{
    width: 90%;
    height: 114px;
    border-radius: 10px;
    border: 1px solid #2F5A76;
    outline: none;
    padding-left: 20px;
    padding-top: 20px;
}



