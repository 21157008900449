.sidebar {
  width: auto;
  height: auto;
  padding: 16px 16px 48px 16px;
  background: linear-gradient(
    151.63deg,
    rgba(255, 255, 255, 0.9) 23.82%,
    rgba(219, 219, 219, 0.18) 98.71%
  );
  /* background-color: rgb(19, 17, 17); */
}

.sidebar1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-top: 48px;
}

.sidebar2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar2 p {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #9aa3af;
  margin: 0;
}

.sidebar3 {
  text-align: end;
  cursor: pointer;
  padding-right: 10px;
}

.sidebar-link.active {
  width: auto;
  height: auto;
  background-color: #ffa5000d;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 16px 40px 16px 40px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  color: #ffa500;
  border-left: 6px solid #ffa500;
}

.sidebar-link.active p {
  color: #ffa500;
}

.sidebar-link {
  text-decoration: none;
  width: auto;
  height: auto;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 16px 40px 16px 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #9aa3af;
}

.sidebar-link:hover {
  border-left: 6px solid #9aa3af;
}

.sidebar-link11 {
  text-decoration: none;
  width: auto;
  height: auto;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 16px 40px 16px 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #f44336;
}

.sidebar-link11:hover {
  border-left: 6px solid #f44336;
}

.sidebar22 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar22 p {
  font-family: "Lexend", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #f44336;
  margin: 0;
}
