.wallet1{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 50px;
    width: 90%;
    margin-top: 30px;
}

.wallet2{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.wallet3 {
  width: auto;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wallet3 p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #4D4E50;
  margin: 0;
}



.wallet4{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 20px;
}


.wallet4 button:nth-child(1){
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 10px;
    background-color: #00F076;
    border: none;
    outline: none;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.73px;
    color: #2E4658;
    margin: 0;
    cursor: pointer;
}

.wallet4 button:nth-child(2){
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 10px;
    background-color: #F44336;
    border: none;
    outline: none;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.73px;
    color: #FFFFFF;
    margin: 0;
    cursor: pointer;
}


.wallet5{
    margin-top: 100px;
}

.wallet6{
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.wallet7{

}

.wallet7 p{
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    letter-spacing: 0.2px;
    margin: 0;
}



.wallet8{
    width:  400px;
    height: 100px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.wallet9{
    width: 293px;
    height: 70px;
    border-radius: 10px;
    border: 1px dotted #4D4E50;
    text-align: center;
}


.wallet9 p{
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #4D4E50;
    letter-spacing: 0.2px;
    margin: 0;
    padding-top: 10px;
}

.wallet9 span{
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #4D4E50;
    letter-spacing: 0.2px;
    text-decoration: underline;
    margin: 0;
}